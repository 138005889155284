* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    padding: 2rem;
    height: inherit;
}

#root {
    height: inherit;
}

html {
    width: 100%;
    height: 100%;
}